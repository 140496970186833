import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login/Login';
// import './assets/css/core-dark.css'
import './assets/css/core.css'
// import './assets/css/theme-bordered-dark.css'
import './assets/css/theme-default.css'
import Dashboard from './pages/dashboard/Dashboard';
import Signup from './pages/signup/Signup';
import PricingSheet from './pages/pricingsheet/PricingSheet';
import AddUser from './pages/user/AddUser';
import UserList from './pages/userlist/UserList';
import Home from './pages/home';
import Thankyou from './pages/thankyou/Thankyou';
import ViewPricingSheet from './pages/viewpricingsheet/ViewPricingSheet';
import FormPassword from './pages/formpassword/FormPassword';

function App() {
  let auth = false;

  if(localStorage.getItem('key') != ""){
    auth = true
  }
  else{
    auth = false;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="form" element={<Home/>}/>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="/" element={<Login/>}/>
        <Route path="sign-up" element={<Signup/>}/>
        <Route path="pricing-sheet" element={<PricingSheet/>}/>
        <Route path="add-user" element={<AddUser/>}/>
        <Route path="user-list" element={<UserList/>}/>
        <Route path="thankyou" element={<Thankyou/>}/>
        <Route path="form-password" element={<FormPassword/>}/>
        <Route path="view-pricing-sheet/:id" element={<ViewPricingSheet/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
