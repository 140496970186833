import React, { useState } from "react";
import "./style.css";
import { TbPlus } from "react-icons/tb";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  let location = useLocation();
  
  const [userKey, setUserKey] = useState(location.search.slice(3))
  // console.log(userKey)

  const [formData, setFormData] = useState(
    {
      UserKey:userKey,
      CarrierName:'',
      FF:'XX',
      ChangeTracker:'',
      ChangeLog:'',
      StartDate:'',
      EndDate:'',
      LaneID:'',
      OriginRegion:'',
      OriginCountry:'',
      OriginCity:'',
      OriginPort:'',
      DesintationRegion:'',
      DestinationCountry: '',
      DestinationWarehouseCode:'',
      DestinationPort:'',
      ContainerSize:'',
      Servicelevel:'',
      DTDSLA:'',
      OriginCustomsPerBLCurrency:'$',
      OriginCustomsPerBL:'',
      OriginPickupPerContainerCurrency:'$',
      OriginPickupPerContainer:'',
      OriginOtherChargesPerBLCurrency:'$',
      OriginOtherChargesPerBL:'',
      OriginTHCPerContainerCurrency:'$',
      OriginTHCPerContainer:'',
      AMSFeePerBLCurrency:'$',
      AMSFeePerBL:'',
      HazmatPerContainerCurrency:'$',
      HazmatPerContainer:'',
      FreightPToPPerContainerCurrency:'$',
      FreightPToPPerContainer:'',
      EMSPerContainerCurrency:'$',
      EMSPerContainer:'',
      BunkerBAFPerContainerCurrency:'$',
      BunkerBAFPerContainer:'',
      TerminalTHCPerContainerCurrency:'$',
      TerminalTHCPerContainer:'',
      OtherChargesPerContainerCurrency:'$',
      OtherChargesPerContainer:'',
      DeliveryPerContainerCurrency:'$',
      DeliveryPerContainer:'',
      DestinationCustomsPerBLCurrency:'$',
      DestinationCustomsPerBL:'',
      TotalChargesPerBLCurrency:'$',
      TotalChargesPerBL:'',
      TotalChargesPerContainerCurrency:'$',
      TotalChargesPerContainer:'',
      TotalDTDCurrency:'$',
      TotalDTD:'',
      MainCarriers:'',
      SailingFrequency:'',
      CargoRouteMap:'',
      OriginTAT:'',
      PTPTransitTime:'',
      DestinationTAT:'',
      DTDTransitTime:'',
      DemurrageFreeDays:'',
      DetentionFreeDays:'',
      OriginInlandMode:'',
      Remarks:'',
      DestinationInlandMode:'',
      ServicelevelPreviousVersion:'',
      Comments:'',
      OldLaneID:'',
    }
  )

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) =>{
    e.preventDefault();

    axios.post('https://api.neommediallc.com:3333/api/ocean-fcls/create', formData)
    .then(function (response) {
      navigate('/thankyou')
    })
    .catch(function (error) {
      console.log(error);
    });

  }


  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          <div className="col-xl">
            <div className="card mb-4">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Carrier</h5>
                      <small className="text-muted float-end">
                        Fill the form below
                      </small>
                    </div>
                    <div className="col-md-5">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        Carrier Name
                      </label>
                      <input
                        type="text"
                        name="CarrierName"
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="John Doe"
                        value={formData.CarrierName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Trade Lane Information</h5>
                    </div>
                    <div className="col-md-2">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        FF
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basic-default-fullname"
                        name="FF"
                        disabled
                        value={formData.FF}
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        Change Tracker
                      </label>
                      <div className="form-check">
                        <input
                          name="ChangeTracker"
                          className="form-check-input"
                          type="radio"
                          value="Yes"
                          onChange={handleChange}
                          id="defaultRadio1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultRadio1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          name="ChangeTracker"
                          className="form-check-input"
                          type="radio"
                          value="No"
                          onChange={handleChange}
                          id="defaultRadio2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultRadio2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        Change Log
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basic-default-fullname"
                        name="ChangeLog"
                        value={formData.ChangeLog}
                        onChange={handleChange}
                        placeholder="Change Log"
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Start Date
                      </label>
                      <div className="bi-datePicker">
                        <input className="form-control" 
                        type="date"
                        name="StartDate"
                        onChange={handleChange}
                        value={formData.StartDate}
                        id="html5-datetime-local-input" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        End Date
                      </label>
                      <div className="bi-datePicker">
                        <input className="form-control" 
                          type="date"
                          name="EndDate"
                          onChange={handleChange}
                          value={formData.EndDate}
                          id="html5-datetime-local-input" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Lane ID
                      </label>
                        <input className="form-control" 
                          type="text"
                          name="LaneID"
                          onChange={handleChange}
                          placeholder="Lane ID"
                          value={formData.LaneID}
                          id="html5-datetime-local-input" />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin Region
                      </label>
                      <select name="OriginRegion" onChange={handleChange} id="defaultSelect" className="form-select">
                        <option>Default select</option>
                        <option value="Asia">Asia</option>
                        <option value="Europe">Europe</option>
                        <option value="North-America">North America</option>
                        <option value="South-America">South America</option>
                        <option value="Africa">Africa</option>
                        <option value="Oceania">Oceania</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin Country
                      </label>
                      <input
                        type="text"
                        name="OriginCountry"
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin Country"
                        onChange={handleChange}
                        value={formData.OriginCountry}
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin City
                      </label>
                      <input
                        type="text"
                        name="OriginCity"
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin City"
                        value={formData.OriginCity}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin Port
                      </label>
                      <input
                        type="text"
                        name="OriginPort"
                        onChange={handleChange}
                        value={formData.OriginPort}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin Port"
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Desintation Region
                      </label>
                      <select name="DesintationRegion" onChange={handleChange} id="defaultSelect" className="form-select">
                        <option>Default select</option>
                        <option value="Asia">Asia</option>
                        <option value="Europe">Europe</option>
                        <option value="North-America">North America</option>
                        <option value="South-America">South America</option>
                        <option value="Africa">Africa</option>
                        <option value="Oceania">Oceania</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination Country
                      </label>
                      <input
                        type="text"
                        name="DestinationCountry"
                        onChange={handleChange}
                        value={formData.DestinationCountry}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Country"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination (Warehouse Code)
                      </label>
                      <input
                        type="text"
                        name="DestinationWarehouseCode"
                        onChange={handleChange}
                        value={formData.DestinationWarehouseCode}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination City (Warehouse Code)"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination Port
                      </label>
                      <input
                        type="text"
                        name="DestinationPort"
                        onChange={handleChange}
                        value={formData.DestinationPort}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Port"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Container Size / SLA</h5>
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Container Size
                      </label>
                      <input
                        type="text"
                        name="ContainerSize"
                        onChange={handleChange}
                        value={formData.ContainerSize}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Container Size"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Service level
                      </label>
                      <input
                        type="text"
                        name="Servicelevel"
                        onChange={handleChange}
                        value={formData.Servicelevel}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Service level"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        DTD SLA
                      </label>
                      <input
                        type="text"
                        name="DTDSLA"
                        onChange={handleChange}
                        value={formData.DTDSLA}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="DTD SLA"
                        required
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Origin</h5>
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="OriginCustomsPerBLCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin Customs per BL
                      </label>
                      <input
                        type="number"
                        name="OriginCustomsPerBL"
                        onChange={handleChange}
                        value={formData.OriginCustomsPerBL}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin Customs per BL"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="OriginPickupPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin Pickup per Container
                      </label>
                      <input
                        type="number"
                        name="OriginPickupPerContainer"
                        onChange={handleChange}
                        value={formData.OriginPickupPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin Pickup per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="OriginOtherChargesPerBLCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin - Other Charges per BL
                      </label>
                      <input
                        type="number"
                        name="OriginOtherChargesPerBL"
                        onChange={handleChange}
                        value={formData.OriginOtherChargesPerBL}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin - Other Charges per BL"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="OriginTHCPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Origin THC per Container
                      </label>
                      <input
                        type="number"
                        name="OriginTHCPerContainer"
                        onChange={handleChange}
                        value={formData.OriginTHCPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin THC per Container"
                        required
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Ocean Freight</h5>
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="AMSFeePerBLCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        AMS Fee per BL
                      </label>
                      <input
                        type="number"
                        name="AMSFeePerBL"
                        onChange={handleChange}
                        value={formData.AMSFeePerBL}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="AMS Fee per BL"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="HazmatPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Hazmat per Container
                      </label>
                      <input
                        type="number"
                        name="HazmatPerContainer"
                        onChange={handleChange}
                        value={formData.HazmatPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Hazmat per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="FreightPToPPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Freight P-To-P per Container
                      </label>
                      <input
                        type="number"
                        name="FreightPToPPerContainer"
                        onChange={handleChange}
                        value={formData.FreightPToPPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Freight Port-To-Port per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="EMSPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        EMS per Container
                      </label>
                      <input
                        type="number"
                        name="EMSPerContainer"
                        onChange={handleChange}
                        value={formData.EMSPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="EMS per Container"
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="BunkerBAFPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Bunker (BAF) per Container
                      </label>
                      <input
                        type="number"
                        name="BunkerBAFPerContainer"
                        onChange={handleChange}
                        value={formData.BunkerBAFPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Bunker (BAF) per Container"
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Destination</h5>
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="TerminalTHCPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Terminal (THC) per Container
                      </label>
                      <input
                        type="number"
                        name="TerminalTHCPerContainer"
                        onChange={handleChange}
                        value={formData.TerminalTHCPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Terminal (THC) per Container"
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="OtherChargesPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Other Charges per Container
                      </label>
                      <input
                        type="number"
                        name="OtherChargesPerContainer"
                        onChange={handleChange}
                        value={formData.OtherChargesPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination - Other Charges per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="DeliveryPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Delivery per Container
                      </label>
                      <input
                        type="number"
                        name="DeliveryPerContainer"
                        onChange={handleChange}
                        value={formData.DeliveryPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Delivery per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="DestinationCustomsPerBLCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination Customs per BL
                      </label>
                      <input
                        type="number"
                        name="DestinationCustomsPerBL"
                        onChange={handleChange}
                        value={formData.DestinationCustomsPerBL}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Customs per BL"
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Total DTD</h5>
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="TotalChargesPerBLCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Total Charges per BL
                      </label>
                      <input
                        type="number"
                        name="TotalChargesPerBL"
                        onChange={handleChange}
                        value={formData.TotalChargesPerBL}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Total Charges per BL"
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="TotalChargesPerContainerCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Total Charges per Container
                      </label>
                      <input
                        type="number"
                        name="TotalChargesPerContainer"
                        onChange={handleChange}
                        value={formData.TotalChargesPerContainer}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Total Charges per Container"
                        required
                      />
                    </div>
                    <div className="col-md-1">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Currency
                      </label>
                      <select name="TotalDTDCurrency" onChange={handleChange} id="currencySelect" className="form-select">
                        <option value="$">$</option>
                        <option value="£">£</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Total DTD
                      </label>
                      <input
                        type="number"
                        name="TotalDTD"
                        onChange={handleChange}
                        value={formData.TotalDTD}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Total DTD"
                        required
                      />
                    </div>
                  </div>
                  <hr className="mt-5"/>
                  <div className="row mb-3 row-gap-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Misc</h5>
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                         Main Carriers 
                      </label>
                      <input
                        type="text"
                        name="MainCarriers"
                        onChange={handleChange}
                        value={formData.MainCarriers}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Main Carriers"
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Sailing Frequency
                      </label>
                      <input
                        type="text"
                        name="SailingFrequency"
                        onChange={handleChange}
                        value={formData.SailingFrequency}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Sailing Frequency "
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Cargo Route Map 
                      </label>
                      <input
                        type="text"
                        name="CargoRouteMap"
                        onChange={handleChange}
                        value={formData.CargoRouteMap}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Cargo Route Map"
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                         Origin TAT  
                      </label>
                      <input
                        type="text"
                        name="OriginTAT"
                        onChange={handleChange}
                        value={formData.OriginTAT}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder=" Origin TAT "
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        PTP Transit Time   
                      </label>
                      <input
                        type="text"
                        name="PTPTransitTime"
                        onChange={handleChange}
                        value={formData.PTPTransitTime}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="PTP Transit Time"
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination TAT   
                      </label>
                      <input
                        type="text"
                        name="DestinationTAT"
                        onChange={handleChange}
                        value={formData.DestinationTAT}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination TAT"
                        required
                      />
                    </div>
                    <div className="col-md-2">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                         DTD Transit Time   
                      </label>
                      <input
                        type="text"
                        name="DTDTransitTime"
                        onChange={handleChange}
                        value={formData.DTDTransitTime}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder=" DTD Transit Time "
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                          Demurrage Free days at destination port  
                      </label>
                      <input
                        type="text"
                        name="DemurrageFreeDays"
                        onChange={handleChange}
                        value={formData.DemurrageFreeDays}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder=" Demurrage Free days at destination port "
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                          Detention Free days at destination port  
                      </label>
                      <input
                        type="text"
                        name="DetentionFreeDays"
                        onChange={handleChange}
                        value={formData.DetentionFreeDays}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder=" Detention Free days at destination port "
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                          Origin Inland Mode 
                      </label>
                      <input
                        type="text"
                        name="OriginInlandMode"
                        onChange={handleChange}
                        value={formData.OriginInlandMode}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Origin Inland Mode"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                          Remarks
                      </label>
                      <textarea
                        style={{resize:"none"}}
                        rows={4}
                        name="Remarks"
                        onChange={handleChange}
                        value={formData.Remarks}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Remarks..."
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Destination Inland Mode 
                      </label>
                      <input
                        type="text"
                        name="DestinationInlandMode"
                        onChange={handleChange}
                        value={formData.DestinationInlandMode}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Destination Inland Mode "
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Service level (Previous version)
                      </label>
                      <input
                        type="text"
                        name="ServicelevelPreviousVersion"
                        onChange={handleChange}
                        value={formData.ServicelevelPreviousVersion}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Service level (Previous version)"
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Comments
                      </label>
                      <input
                        type="text"
                        name="Comments"
                        onChange={handleChange}
                        value={formData.Comments}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Comments"
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label
                        htmlFor="bs-datepicker-basic"
                        className="form-label"
                      >
                        Old Lane ID
                      </label>
                      <input
                        type="text"
                        name="OldLaneID"
                        onChange={handleChange}
                        value={formData.OldLaneID}
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Old Lane ID"
                        required
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light mt-4 float-end"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
