import React, {useEffect, useState} from 'react'
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import UserForm from '../../components/UserForm';
import Table from '../../components/Table';
import axios from 'axios';
import './style.css'
import { useNavigate } from 'react-router';

const UserList = () => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState();

  useEffect(()=>{
    axios.defaults.withCredentials = true
    axios.get('https://api.neommediallc.com:3333/users')
    .then(function (response) {
      //console.log(response.data.data);
      setUserData(response?.data?.data)
      //navigate('/user-list')
    })
    .catch(function (error) {
      console.log(error);
    });

    if(localStorage.getItem('key') == "")
      {
        navigate('/')
      }

  },[])

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="menu-inner-shadow"></div>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* <h4 className="py-3 mb-4">Add User</h4> */}

              <div className="row">
                <div className='card user-list-card'>
                <h5 className="card-header">User List</h5>
                    { userData ? <Table userData={userData}/> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserList