import React, {useState, useEffect} from "react";
import {
  TbTrash 
} from "react-icons/tb";
import DataTable from "react-data-table-component";
import Modal from '@mui/material/Modal';



const Table = ({userData}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteOpen = () => setDeleteModal(true);
  const handleDeleteClose = () => setDeleteModal(false);

  const [tableuserData, setTableUserData] = useState()

  useEffect(() => {
    setTableUserData(userData)
  }, [])
  console.log(tableuserData)

  const columns = [
    {
      name: 'User Name',
      selector: row => row.fullName,
      sortable: true,
      grow:'0',
      minWidth:'33%'
    },
    {
      name: 'User Email',
      selector: row => row.email,
      sortable: true,
      grow:'0',
      minWidth:'33%'
    },
    {
      name: 'Actions',
      cell: row => <button className="btn btn-danger btn-sm" onClick={handleDeleteOpen}> <TbTrash style={{marginRight:"6px", marginBottom:"2px"}}/> Delete  </button>,
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: "200px",
      button: true,
      minWidth:'33%',
      grow:'1'
    },
  ]


  return (
    <div className="table-responsive text-nowrap">
      <DataTable
        columns={columns}
        data={tableuserData}
        responsive
        pagination
        highlightOnHover
      />
      <Modal
        open={deleteModal}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalWrapper">
          <h3>
            Are you sure you want to Delete this?
          </h3>
          <div className="modalBtn">
            <button className="btn btn-outline-info " onClick={handleDeleteClose}>
              Close
            </button>
            <button className="btn btn-outline-info">
              Delete
            </button>
            
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
