import React, {useState} from "react";
import { TbPlus } from "react-icons/tb";
import axios from "axios";
import { useNavigate } from "react-router";



const UserForm = () => {

  const navigate = useNavigate()

  const [addUser, setAddUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAddUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) =>{
    e.preventDefault();

    axios.defaults.withCredentials = true

    axios.post('https://api.neommediallc.com:3333/users', addUser)
    .then(function (response) {
      console.log(response);
      navigate('/user-list')
    })
    .catch(function (error) {
      console.log(error);
    });

  }


  return (
    <>
      <div className="col-xl">
        <div className="card mb-4">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5 className="mb-3">Add User Form</h5>
            <small className="text-muted float-end">Fill the form below</small>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-fullname">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={addUser.name}
                  className="form-control"
                  id="basic-default-fullname"
                  placeholder="John Doe"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="basic-default-email">
                  Email
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={addUser.email}
                    id="basic-default-email"
                    className="form-control"
                    placeholder="john@gmail.com"
                    aria-label="john.doe"
                    aria-describedby="basic-default-email2"
                    required
                  />
                </div>
                <div className="form-text">
                  You can use letters, numbers &amp; periods
                </div>
              </div>
              <div className="mb-3">
                <div className="form-password-toggle">
                  <label className="form-label" htmlFor="multicol-password">
                    Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={addUser.password}
                      id="multicol-password"
                      className="form-control"
                      placeholder="············"
                      aria-describedby="multicol-password2"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      id="multicol-password2"
                    >
                      <i className="ti ti-eye-off"></i>
                    </span>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                <TbPlus style={{ marginRight: "10px" }} size={17} />
                Add User
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
