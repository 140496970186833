import React, { useState } from "react";
import { useNavigate } from "react-router";
import { TbHelpCircleFilled } from "react-icons/tb";

const FormPassword = () => {
  const [pass, setPass] = useState()
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const confirmPass = "admin123"

 
  const handleSubmit = (e) => {
      e.preventDefault();
      if (pass == confirmPass) {
        navigate(`/form?v=${localStorage.getItem("key")}`)
        
      }
      else {
        setError(true)
      }
  }

  return (
    <div className="password-form-wrapper">
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            {/* <!-- Login --> */}
            <div className="card">
              <div className="card-body">
                <a href="#" className="app-brand-link gap-2">
                  <span className="app-brand-text demo text-body fw-bold ml-0">
                    Password Protected Page
                  </span>
                </a>
                <input
                  className="form-control mt-3"
                  type="text"
                  name="passProtected"
                  onChange={(e)=> setPass(e.target.value)}
                  placeholder="Enter password Here" />
                  {error ? <div class="alert alert-danger mt-3" role="alert">
                    <small><TbHelpCircleFilled size={22}/> Password is Incorrect</small>
                  </div> :''}
                <button onClick={handleSubmit} className="mt-3 btn btn-primary btn-small">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPassword;
