import React, {useEffect} from "react";
import { useNavigate } from "react-router";



  
const Thankyou = () => {
  const navigate = useNavigate()

  useEffect(()=>{

    if(localStorage.getItem('key') == '')
      {
        navigate('/')
      }
  },[])


  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          <div className="col-xl">
            <div className="card mb-4">
              <div className="card-body text-center">
                <div className="card-header d-flex justify-content-center align-items-center">
                      <h2 className="mb-0 text-center">Thankyou</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
