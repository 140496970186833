import React from "react";
import { TbMoneybag , TbUserCog , TbGitPullRequest , TbProgressCheck  } from "react-icons/tb";

const Card = () => {
  return (
    <>
        <div className="col-sm-6 col-lg-3 mb-4">
                  <div className="card card-border-shadow-primary">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2 pb-1">
                        <div className="avatar me-2">
                          <span className="avatar-initial rounded bg-label-primary">
                            <TbMoneybag  size={24}/>
                          </span>
                        </div>
                        <h4 className="ms-1 mb-0">420,000</h4>
                      </div>
                      <p className="mb-1">Total Earnings</p>
                      <p className="mb-0">
                        <span className="fw-medium me-1">+18.2%</span>
                        <small className="text-muted">than last week</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  <div className="card card-border-shadow-warning">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2 pb-1">
                        <div className="avatar me-2">
                          <span className="avatar-initial rounded bg-label-warning">
                            <TbUserCog size={24}/>
                          </span>
                        </div>
                        <h4 className="ms-1 mb-0">4</h4>
                      </div>
                      <p className="mb-1">Total Users</p>
                      <p className="mb-0">
                        <span className="fw-medium me-1">-8.7%</span>
                        <small className="text-muted">than last week</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  <div className="card card-border-shadow-danger">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2 pb-1">
                        <div className="avatar me-2">
                          <span className="avatar-initial rounded bg-label-danger">
                            <TbGitPullRequest size={24} />
                          </span>
                        </div>
                        <h4 className="ms-1 mb-0">27</h4>
                      </div>
                      <p className="mb-1">Number of Request</p>
                      <p className="mb-0">
                        <span className="fw-medium me-1">+4.3%</span>
                        <small className="text-muted">than last week</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-4">
                  <div className="card card-border-shadow-info">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-2 pb-1">
                        <div className="avatar me-2">
                          <span className="avatar-initial rounded bg-label-info">
                            <TbProgressCheck size={24} />
                          </span>
                        </div>
                        <h4 className="ms-1 mb-0">89</h4>
                      </div>
                      <p className="mb-1">Numbers of Completed Requests</p>
                      <p className="mb-0">
                        <span className="fw-medium me-1">+2.5%</span>
                        <small className="text-muted">than last week</small>
                      </p>
                    </div>
                  </div>
                </div>
    </>
  );
};

export default Card;
