import React, {useEffect} from 'react'
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import UserForm from '../../components/UserForm';
import { useNavigate } from 'react-router';

const AddUser = () => {

  const navigate = useNavigate()

  useEffect(()=>{

    if(localStorage.getItem('key') == '')
      {
        navigate('/')
      }
  },[])

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="menu-inner-shadow"></div>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* <h4 className="py-3 mb-4">Add User</h4> */}

              <div className="row">
                <UserForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUser