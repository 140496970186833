// React Imports
import { useEffect, useMemo, useState } from "react";
import "./style.css";
import DataTable, { TableColumn } from "react-data-table-component";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import datasheet from "./data";
import axios from "axios";
import { TbTrash } from "react-icons/tb";
import SheetTable from "../../components/SheetTable";
import { Link, useNavigate } from "react-router-dom";


const PricingSheet = () => {
  const navigate = useNavigate()
  const [sheetData, setSheetData] = useState();

  useEffect(() => {
    axios.defaults.withCredentials = true;

    axios
      .get("https://api.neommediallc.com:3333/api/ocean-fcls/list")
      .then(function (response) {
        setSheetData(response?.data?.data);
        console.log(sheetData);
        //navigate('/user-list')
      })
      .catch(function (error) {
        console.log(error);
      });

      if(localStorage.getItem('key') == '')
        {
          navigate('/')
        }
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="menu-inner-shadow"></div>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* <h4 className="py-3 mb-4">Pricing Sheet</h4> */}

              <div className="row">
                <div className="card pricing-sheet">
                  <h5 className="card-header">Pricing Sheet</h5>
                  <div className="d-flex justify-content-start align-items-center">
                    <small>Form Link</small>
                    <Link
                      target="_blank"
                      to={`/form-password`}
                    >
                      <span className="ms-3 py-3 d-block">
                        http://otm.neommediallc.com:3000
                        {`/form?v=${localStorage.getItem("key")}`}
                      </span>
                    </Link>
                  </div>
                  {sheetData ? <SheetTable sheetData={sheetData} /> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSheet;
