import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { TbSmartHome, TbFileSpreadsheet, TbUsersPlus, TbUser, TbListTree } from "react-icons/tb";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const SideBar: React.FC = () => {
    const navigate = useNavigate()
    // const [activeTab, setActiveTab] = useState<string>();
    const [subMenu, setSubMenu] = useState(false)

    // const handleTabClick = (tab: string) => {
    //     setActiveTab(tab);
    // };

    const handleLogout = () =>{
    
    axios.defaults.withCredentials = true
    axios.post('https://api.neommediallc.com:3333/logout')
    .then(function (response) {
      if(response?.data?.success) localStorage.setItem('key', '')
      
      if(localStorage.getItem('key') != null) navigate('/')
    })
    .catch(function (error) {
      console.log(error);
    });
    }

  return (
    <aside
          id="layout-menu"
          className="layout-menu menu-vertical menu bg-menu-theme"
          data-bg-className="bg-menu-theme"
        >
          <div className="app-brand demo">
            <Link to="/dashboard" className="app-brand-link">
              <span className="app-brand-logo demo">
                <svg
                  width="32"
                  height="22"
                  viewBox="0 0 32 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                    fill="#7367F0"
                  ></path>
                  <path
                    opacity="0.06"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                    fill="#161616"
                  ></path>
                  <path
                    opacity="0.06"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                    fill="#161616"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                    fill="#7367F0"
                  ></path>
                </svg>
              </span>
              <span className="app-brand-text demo menu-text fw-bold">Rate Genie</span>
            </Link>

          </div>
          <div className="menu-inner-shadow"></div>
          <ul className="menu-inner py-1 ps ps--active-y">
          <li className="menu-item">
              <Link to="/dashboard" className="menu-link"
            //   onClick={() => handleTabClick('tab1')}
            //   className={`menu-link menu-toggle ${activeTab === 'tab1' ? 'active' : ''}`}
              >
                <TbSmartHome size={22}/>
                <div>Dashboards</div>
              </Link>
            </li>
          <li className="menu-item">
            <Link to="/pricing-sheet" className="menu-link"
            // onClick={() => handleTabClick('tab2')}
            // className={`menu-link menu-toggle ${activeTab === 'tab2'? 'active' : ''}`}
            >
                <TbFileSpreadsheet  size={22}/>
                <div>Pricing Sheet</div>
            </Link>
          </li>
          <li className="menu-header small text-uppercase">
              <span className="menu-header-text" data-i18n="Forms &amp; Tables">Systems</span>
          </li>
          <li onClick={()=> setSubMenu(true)}
          className={`menu-item ${subMenu == true ? 'open' : ''}`}>
            <a href="#"
            className="menu-link menu-toggle">
                <TbUser  size={22}/>
                <div>Users</div>
            </a>
            <ul className="menu-sub">
            <li className="menu-item">
                  <Link to="/user-list" className="menu-link"
                //   onClick={() => handleTabClick('tab4')}
                //   className={`menu-link ${activeTab === 'tab4'? 'active' : ''}`}
                  >
                      <TbListTree/>
                    <div data-i18n="ChartJS">User List</div>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/add-user" className="menu-link "
                //   onClick={() => handleTabClick('tab3')}
                //   className={`menu-link ${activeTab === 'tab3'? 'active' : ''}`}
                  >
                      <TbUsersPlus/>
                    <div data-i18n="ChartJS">Add User</div>
                  </Link>
                </li>
              </ul>
          </li>
          <li className="menu-item log">
                  <Link to="#" className="menu-link " onClick={handleLogout}
                //   onClick={() => handleTabClick('tab3')}
                //   className={`menu-link ${activeTab === 'tab3'? 'active' : ''}`}
                  >
                    <div data-i18n="ChartJS">Logout</div>
                  </Link>
                </li>
          </ul>
        </aside>
  )
}

export default SideBar