import React, { useState } from "react";
import { FiEyeOff } from "react-icons/fi";
import { FaFacebookF, FaGoogle, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios'

const Signup = () => {
  const navigate = useNavigate();

  const [signUpData, setSignUpData] = useState({
    name: "",
    company: "",
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSignUpData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) =>{
    e.preventDefault();

    axios.post('https://api.neommediallc.com:3333/register', signUpData)
    .then(function (response) {
      navigate('/')
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          {/* <!-- Register Card --> */}
          <div className="card">
            <div className="card-body">
              {/* <!-- Logo --> */}
              <div className="app-brand justify-content-center mb-4 mt-2">
                <a href="#" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <svg
                      width="32"
                      height="22"
                      viewBox="0 0 32 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                        fill="#7367F0"
                      ></path>
                      <path
                        opacity="0.06"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                        fill="#161616"
                      ></path>
                      <path
                        opacity="0.06"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                        fill="#161616"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                        fill="#7367F0"
                      ></path>
                    </svg>
                  </span>
                  <span className="app-brand-text demo text-body fw-bold ms-1">
                    Rate Genie
                  </span>
                </a>
              </div>
              {/* <!-- /Logo --> */}
              <h4 className="mb-1 pt-2">Registration Form</h4>
              {/* <p className="mb-4">Make your app management easy and fun!</p> */}

              <form onSubmit={handleSubmit}
                id="formAuthentication"
                className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework"
                action="index.html"
                method="GET"
                noValidate
              >
                <div className="mb-3 fv-plugins-icon-container">
                  <label htmlFor="username" className="form-label">
                    Name
                  </label>
                  <input
                    value={signUpData.name}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    autoFocus
                  />
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>
                <div className="mb-3 fv-plugins-icon-container">
                  <label htmlFor="username" className="form-label">
                    Company Name
                  </label>
                  <input
                    value={signUpData.company}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="username"
                    name="company"
                    placeholder="Enter your Company Name"
                    autoFocus
                  />
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>
                <div className="mb-3 fv-plugins-icon-container">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    value={signUpData.email}
                    onChange={handleChange}
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>
                <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>

                  <div className="input-group input-group-merge has-validation">
                    <input
                      type="password"
                      id="password"
                      onChange={handleChange}
                      className="form-control"
                      name="password"
                      value={signUpData.password}
                      placeholder="············"
                      aria-describedby="password"
                    />
                    <span className="input-group-text cursor-pointer">
                      <FiEyeOff />
                    </span>
                  </div>
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>

                <div className="mb-3 fv-plugins-icon-container">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="terms-conditions"
                      name="terms"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="terms-conditions"
                    >
                      I agree to
                      <a href="javascript:void(0);">
                        {" "}
                        privacy policy &amp; terms
                      </a>
                    </label>
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                  </div>
                </div>
                <button className="btn btn-primary d-grid w-100 waves-effect waves-light">
                  Sign up
                </button>
                <input type="hidden" />
              </form>

              <p className="text-center">
                <span>Already have an account? </span>
                <Link to="/">
                  <span> Sign in instead</span>
                </Link>
              </p>

            </div>
          </div>
          {/* <!-- Register Card --> */}
        </div>
      </div>
    </div>
  );
};

export default Signup;
