import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import Card from "../../components/Card";

const ViewPricingSheet = () => {
  const [sheetData, setSheetData] = useState();
  const navigate = useNavigate()

  const params = useParams();

  const getSheetData = async () => {

    const response = await axios.get(`https://api.neommediallc.com:3333/api/ocean-fcls/list/${params.id}`)
    setSheetData(response.data.data)

  }

  useEffect(() => {
    axios.defaults.withCredentials = true;
    getSheetData()

    if(localStorage.getItem('key') == '')
      {
        navigate('/')
      }
  }, []);

  //console.log(sheetData)

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="menu-inner-shadow"></div>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper view-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="d-flex justify-content-between align-items-center ">

              <h4 className="py-3 mb-4">View List</h4>
              <small className="text-muted float-end">
                                  <Link to={"/pricing-sheet"} className="link-primary">
                                    {`<<`} Go Back to List
                                  </Link>
                                </small>
              </div>
              

              <div className="row">
                    <div className="col-xl">
                      <div className="card mb-4">
                        <div className="card-body">
                          <form>
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Trade Lane Information</h5>
                              </div>
                              <div className="col-md-1 d-flex align-items-center gap-2">
                                <label
                                  className="form-label mb-0"
                                  htmlFor="basic-default-fullname"
                                >
                                  FF:
                                </label>
                                <strong className="mb-0 text-black"> {sheetData?.ff} </strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  className="form-label mb-0"
                                  htmlFor="basic-default-fullname"
                                >
                                  Change Tracker:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.changeTracker}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  className="form-label mb-0"
                                  htmlFor="basic-default-fullname"
                                >
                                  Change Log:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.changeLog}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Start Date:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.startDate ? (sheetData?.startDate).slice(0, 10) :''}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  End Date:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.endDate ? (sheetData?.endDate).slice(0, 10) : ''}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Lane ID:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.laneId}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin Region:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originRegion}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin Country:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originCountry}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin City:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originCity}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin Port:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originPort}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Desintation Region:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationRegion}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination Country:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationCountry}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination (Warehouse Code):
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationCity}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination Port
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationPort}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Container Size / SLA</h5>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Container Size:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.containerSize}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Service level:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.serviceLevel}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  DTD SLA:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.serviceLevel}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Origin</h5>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin Customs per BL:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originCustomsPerBl}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label"
                                >
                                  Origin Pickup per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originInlandPickupPerCont}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin - Other Charges per BL:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originOtherChargesPerBl}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin THC per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originThcPerCont}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Ocean Freight</h5>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0 "
                                >
                                  AMS Fee per BL:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.amsFeePerBl}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Hazmat per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.hazmatPerContainer}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Freight P-To-P per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.freightPortToPortPerCont}</strong>
                              </div>
                              <div className="col-md-2 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  EMS per Container
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.emsPerContainer}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Bunker (BAF) per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.bunkerPerContainer}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Destination</h5>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Terminal (THC) per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationTerminalPerCont}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Other Charges per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationOtherChargesPerCont}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Delivery per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationDeliveryPerCont}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination Customs per BL:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationCustomsPerBl}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Total DTD</h5>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Total Charges per BL:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.totalChargesPerBl}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Total Charges per Container:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.totalChargesPerCont}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Total DTD:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.totalDtd}</strong>
                              </div>
                            </div>
                            <hr className="mt-5" />
                            <div className="row mb-3 row-gap-4">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Misc</h5>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Main Carriers:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.mainCarriers}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Sailing Frequency:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.sailingFrequency}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Cargo Route Map:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.cargoRouteMap}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Origin TAT:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originTat}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  PTP Transit Time:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.ptpTransitTime}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination TAT
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationTat}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  DTD Transit Time
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.dtdTransitTime}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Demurrage Free destination port
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.demurrageFreeDaysDestPort}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Detention Free destination port
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.detentionFreeDaysDestPort}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0 "
                                >
                                  Origin Inland Mode:
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.originInlandMode}</strong>
                              </div>
                              <div className="col-md-5 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Remarks
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.remarks}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Destination Inland Mode
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.destinationInlandMode}</strong>
                              </div>
                              <div className="col-md-4 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0"
                                >
                                  Service level (Previous version)
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.serviceLevelPrevVersion}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0 "
                                >
                                  Comments
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.comments}</strong>
                              </div>
                              <div className="col-md-3 d-flex align-items-center gap-2">
                                <label
                                  htmlFor="bs-datepicker-basic"
                                  className="form-label mb-0 "
                                >
                                  Old Lane ID
                                </label>
                                <strong className="col mb-0 text-black w-auto">{sheetData?.oldLaneId}</strong>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div></div></div>
  );
};

export default ViewPricingSheet;
