import React, { useEffect, useState } from "react";
import { TbTrash } from "react-icons/tb";
import DataTable from "react-data-table-component";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";


const SheetTable = ({ sheetData }) => {

  const navigate = useNavigate();

  const [approveModal, setApproveModal] = useState(false);
  const handleApproveOpen = () => setApproveModal(true);
  const handleApproveclose = () => setApproveModal(false);

  const [rejectModal, setRejectModal] = useState(false);
  const handleRejectOpen = () => setRejectModal(true);
  const handleRejectclose = () => setRejectModal(false);

  const [priceData, setPriceData] = useState()

  useEffect(() => {
    setPriceData(sheetData)
    console.log(sheetData)
  }, [])


  const columns = [
    {
      name: 'Carier Name',
      selector: row => row.career.name,
      center: true,
      sortable: true,
      allowOverflow: true,
      grow: '1'
    },
    // {
    //   name: 'Change Tracker',
    //   selector: row => row.changeTracker,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.changeTracker}</span>,
    //   center: true,
    //   minWidth: "100px",
    // },
    // {
    //   name: 'Change Log',
    //   selector: row => row.changeLog,
    //   center: true,
    //   allowOverflow: true,
    // },
    // {
    //   name: 'Start Date',
    //   selector: row => row.startDate,
    //   center: true,
    //   sortable: true,
    //   minWidth: "110px"
    // },
    // {
    //   name: 'End Date',
    //   selector: row => row.endDate,
    //   center: true,
    //   sortable: true,
    //   minWidth: "110px"
    // },
    {
      name: 'Lane ID',
      center: true,
      selector: row => row.laneId,
      cell: row => <span className='badge bg-label-primary me-1'> {row.laneId}</span>
    },
    // {
    //   name: 'Origin Region',
    //   selector: row => row.originRegion,
    //   center: true,
    //   minWidth: "130px"
    // },
    {
      name: 'Origin Country',
      selector: row => row.originCountry,
      center: true,
    },
    // {
    //   name: 'Origin City',
    //   center: true,
    //   selector: row => row.originCity,
    // },
    // {
    //   name: 'Origin Port',
    //   center: true,
    //   selector: row => row.originPort,
    // },
    // {
    //   name: 'Destination Region',
    //   selector: row => row.destinationRegion,
    //   center: true,
    //   minWidth: "150px"
    // },
    {
      name: 'Destination Country',
      selector: row => row.destinationCountry,
      center: true,
    },
    // {
    //   name: 'Destination City Warehouse Code',
    //   selector: row => row.destinationCity,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.destinationCity}</span>,
    //   center: true,
    //   minWidth: "230px"
    // },
    // {
    //   name: 'Destination Port',
    //   selector: row => row.destinationPort,
    //   center: true,
    //   minWidth: "130px"
    // },
    // {
    //   name: 'Container Size',
    //   selector: row => row.containerSize,
    //   center: true,
    //   minWidth: "120px"
    // },
    // {
    //   name: 'Service Level',
    //   center: true,
    //   selector: row => row.serviceLevel,
    // },
    // {
    //   name: 'DTD SLA',
    //   center: true,
    //   selector: row => row.dtdSla,
    // },
    // {
    //   name: 'Origin Customs Per BL',
    //   selector: row => row.originCustomsPerBl,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.originCustomsPerBl}</span>,
    //   center: true,
    //   minWidth: "180px"
    // },
    // {
    //   name: 'Origin Inland Pickup Per Container',
    //   selector: row => row.originInlandPickupPerCont,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.originInlandPickupPerCont}</span>,
    //   center: true,
    //   minWidth: "230px"
    // },
    // {
    //   name: 'Origin Other Charges Per BL',
    //   selector: row => row.originOtherChargesPerBl,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.originOtherChargesPerBl}</span>,
    //   center: true,
    //   minWidth: "200px"
    // },
    // {
    //   name: 'Origin THC Per Container',
    //   selector: row => row.originThcPerCont,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.originThcPerCont}</span>,
    //   minWidth: "180px",
    //   center: true,
    // },
    // {
    //   name: 'AMS Fee Per BL',
    //   selector: row => row.amsFeePerBl,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.amsFeePerBl}</span>,
    //   center: true,
    // },
    // {
    //   name: 'Hazmat Per Container',
    //   selector: row => row.hazmatPerContainer,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.hazmatPerContainer}</span>,
    //   minWidth: "180px",
    //   center: true,
    // },
    // {
    //   name: 'Freight PortToPort Per Container',
    //   selector: row => row.freightPortToPortPerCont,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.freightPortToPortPerCont}</span>,
    //   minWidth: "220px",
    //   center: true,
    // },
    // {
    //   name: 'EMS Per Container',
    //   selector: row => row.emsPerContainer,
    //   cell: row => <span className='badge bg-label-primary me-1'> {row.emsPerContainer}</span>,
    //   minWidth: "150px",
    //   center: true,
    // },
    // {
    //   name: 'Bunker BAF Per Container',
    //   selector: row => row.bunkerPerContainer,
    //   center: true,
    // },
    // {
    //   name: 'Destination Terminal THC Per Container',
    //   selector: row => row.destinationTerminalPerCont,
    //   minWidth: "270px",
    //   center: true,
    // },
    // {
    //   name: 'Destination Other Charges Per Container',
    //   selector: row => row.destinationOtherChargesPerCont,
    //   minWidth: "200px",
    //   center: true,
    // },
    // {
    //   name: 'Destination Delivery Per Container',
    //   selector: row => row.destinationDeliveryPerCont,
    //   minWidth: "200px",
    //   center: true,
    // },
    // {
    //   name: 'Destination Customs Per BL',
    //   selector: row => row.destinationCustomsPerBl,
    //   minWidth: "200px",
    //   center: true,
    // },
    // {
    //   name: 'Total Charges Per BL',
    //   selector: row => row.totalChargesPerBl,
    //   center: true,
    // },
    // {
    //   name: 'Total Charges Per Container',
    //   selector: row => row.totalChargesPerCont,
    //   minWidth: "140px",
    //   center: true,
    // },
    // {
    //   name: 'Total DTD',
    //   selector: row => row.totalDtd,
    //   center: true,
    // },
    // {
    //   name: 'Main Carriers',
    //   selector: row => row.mainCarriers,
    //   center: true,
    // },
    // {
    //   name: 'Sailing Frequency',
    //   selector: row => row.sailingFrequency,
    //   center: true,
    // },
    // {
    //   name: 'Cargo Route Map',
    //   selector: row => row.cargoRouteMap,
    //   center: true,
    // },
    // {
    //   name: 'Origin TAT',
    //   selector: row => row.originTat,
    //   center: true,
    // },
    // {
    //   name: 'PTP Transit Time',
    //   selector: row => row.ptpTransitTime,
    //   center: true,
    // },
    // {
    //   name: 'Destination TAT',
    //   selector: row => row.destinationTat,
    //   center: true,
    // },
    // {
    //   name: 'DTD Transit Time',
    //   selector: row => row.dtdTransitTime,
    //   center: true,
    // },
    // {
    //   name: 'Demurrage Free Destination Port',
    //   selector: row => row.demurrageFreeDaysDestPort,
    //   minWidth: "180px",
    //   center: true,
    // },
    // {
    //   name: 'Detention Free Destination Port',
    //   selector: row => row.detentionFreeDaysDestPort,
    //   minWidth: "180px",
    //   center: true,
    // },
    // {
    //   name: 'Origin In land Mode',
    //   selector: row => row.originInlandMode,
    //   center: true,
    // },
    {
      name: 'Remarks',
      selector: row => row.remarks,
      center: true,
      grow: '1',
    },
    // {
    //   name: 'Destination In land Mode',
    //   selector: row => row.destinationInlandMode,
    //   center: true,
    // },
    // {
    //   name: 'Service level Previous Version',
    //   selector: row => row.serviceLevelPrevVersion,
    //   minWidth: "200px",
    //   center: true,
    // },
    // {
    //   name: 'Old Lane ID',
    //   selector: row => row.oldLaneId,
    //   center: true,
    // },
    {
      name: 'Actions',
      cell: row => <>
      <button className='btn btn-success btn-sm mx-1' onClick={handleApproveOpen}> 
        Approve 
      </button> 
      <button className='btn btn-danger btn-sm mx-1' onClick={handleRejectOpen}> 
        Reject 
      </button>
      <Link className='btn btn-info btn-sm mx-1' to={`/view-pricing-sheet/${row.id}`}>
        View
      </Link>
      </>,
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: "250px",
      button: true,
    },
  ];



  return (
    <>
      {priceData ? <DataTable
        title="Pricing Sheet"
        columns={columns}
        data={priceData}
        responsive
        pagination
        striped
        highlightOnHover
        onRowClicked={(row, event) => navigate(`/view-pricing-sheet/${row.id}`)}
      /> : <Stack spacing={1} sx={{
        marginBottom: '2rem',
      }}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="rectangular" width={'100%'} height={40} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
        <Skeleton variant="rounded" width={'100%'} height={60} />
      </Stack>}
      <Modal
        open={approveModal ? approveModal : rejectModal}
        onClose={approveModal ? handleApproveclose : handleRejectclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalWrapper">
          <h3>
            Are you sure you want to {approveModal ? "Approve this?" : "Reject this?"}
          </h3>
          <p>{approveModal ? "Are you sure you want to approve this pricing? This will send the pricing to OTM." : "Are you sure you want to disapprove this pricing?"}</p>
          <div className="modalBtn">
            <button className="btn btn-outline-info bi-close" onClick={approveModal ? handleApproveclose : handleRejectclose}>
              X
            </button>
            {approveModal ?
              <button className="btn btn-outline-info btn-approve">
                Approve
              </button> :
              <button className="btn btn-outline-info btn-reject">
                Reject
              </button>
            }

          </div>
        </div>
      </Modal>
    </>
  );
};

export default SheetTable;
